import React from "react"
import { Layout } from "../components/common"
import GithubSvg from '../images/github.svg'
import LinkedInSvg from '../images/linkedin.svg'

const Tile = ({ Image, link, handle, platform }) => (
    <a className="tile" href={link} target="_blank" rel="noopener noreferrer">
        {Image}
        <div style={{ textAlign: `left` }}>
            <div className="tile_platform">{platform}</div>
            <div className="tile_handle" style={{ marginTop: `0.2rem` }}>{handle}</div>
        </div>
    </a>
)

const tiles = [
    { Image: <img src={GithubSvg} height={48} width={48} />, link: `https://www.github.com/pennwu1`, handle: `@pennwu1`, platform: `Github` },
    { Image: <img src={LinkedInSvg} height={48} width={48} />, link: `https://www.linkedin.com/in/pennwu`, handle: `@pennwu`, platform: `LinkedIn` },
]

const AboutMePage = () => (
    <Layout>
        <div className="container" style={{ maxWidth: 700 }}>
            <article className="content" style={{ textAlign: `center` }}>
                <h1 className="content-title">About Me</h1>
                <section className="content-body">
                    Hi, I&apos;m Penn. I live in New York City and I&apos;m
                    interested in web, mobile, and backend development. I
                    currently work as a software engineer at Brigit, building a
                    new product that helps people improve their credit score.
                </section>
                <h2>Contact</h2>
                <section className="content-body">
                    <p>
                        If you want to get in touch,{` `}
                        <a href="mailto:pennwu2@gmail.com">send me an email</a>
                        {` `}
                        or find me elsewhere on the web:
                    </p>
                </section>
                <div className="tiles">
                    {tiles.map(tileProps => <Tile key={tileProps.platform} {...tileProps} />)}
                </div>
            </article>
        </div>
    </Layout>
)

export default AboutMePage
